import React from "react";
import "./testimonial.css";
import Image2 from "../Images/Image2.jpeg";

function testimonial() {
  return (
    <div className="testimonial">
      <h2>What people are saying!</h2>
      <div className="cards_container">
        <div className="card">
          <div className="circle_image">
            {/* <img src={Image2} alt="img" className="Image2"/> */}
          </div>
          <i class="fa-solid fa-quote-left"></i>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <h5>John Doe</h5>
          <div className="rating">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
          </div>
        </div>
        <div className="card">
          <div className="circle_image">
            {/* <img src="" alt="img" /> */}
          </div>
          <i class="fa-solid fa-quote-left"></i>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <h5>John Doe</h5>
          <div className="rating">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
          </div>
        </div>
        <div className="card">
          <div className="circle_image">
            {/* <img src="" alt="img" /> */}
            
          </div>

          <i class="fa-solid fa-quote-left "></i>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <h5>John Doe</h5>
          <div className="rating">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
          </div>
        </div>
        <div className="card">
          <div className="circle_image">{/* <img src="" alt="img" /> */}</div>

          <i class="fa-solid fa-quote-left "></i>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <h5>John Doe</h5>
          <div className="rating">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
          </div>
        </div>
        <div className="card">
          <div className="circle_image">{/* <img src="" alt="img" /> */}</div>

          <i class="fa-solid fa-quote-left "></i>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <h5>John Doe</h5>
          <div className="rating">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default testimonial;
