import { useState } from "react";
import Testimonial from "./Testimonial";
import RequestSection from "./RequestSection";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import Hero from "./Hero";
import SideNav from "./SideNav";
import ContactUsPage from "./ContactUsPage";
import ChatwootWidget from "./ChatwootWidget";
import Navbar from "./Navbar";

function LandingPage(props) {
  // const [sideNavOpen, setsideNavOpen] = useState(false);

  // const openNav = () => {
  //   if (!sideNavOpen) {
  //     setsideNavOpen(true);
  //   } else {
  //     setsideNavOpen(false);
  //   }
  // };

  // let sideNav;

  // if (sideNavOpen) {
  //   sideNav = <SideNav sidenav="side_nav" />;
  // }
  return (
    <div className="landing_page_cont">
      <Hero />
      <AboutUs />
      <RequestSection />
      {/* <Testimonial /> */}
      <Footer />
      
      <ChatwootWidget />
    </div>
  );
}

export default LandingPage;
