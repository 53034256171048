import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import illustration2 from "../Images/illustration2.png";
import ipadmini from "../Images/ipadmini.png";
import Footer from "./Footer";
import Button from "./Buttons/ButtonCustom.js";
import { AiOutlineWechat } from "react-icons/ai";
import { RiCustomerService2Line } from "react-icons/ri";
import ChatwootWidget from "./ChatwootWidget";

function Features(props) {
  return (
    <div className="features">
      <header className="feature-hero">
        <div className="hero-content">
          <div className="left-side">
            <h1>
              Test your potential <br /> employees seamlessly <br />
              without <i>bias.</i>
            </h1>
            <p>
              Let’s help you build a better workforce. Our potential- driven
              assessment helps get the right and best talent for your business.
            </p>
          </div>
          <div className="right-side">
            <img
              src={illustration2}
              alt={illustration2}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </header>
      <section className="first-sect">
        <h2>
          Hire Smarter! <br />
          Get The Best Talent always.
        </h2>
        <div className="first-sect-content-container">
          <div className="left-side content">
            <img src={ipadmini} alt={ipadmini} style={{ width: "100%" }} />
          </div>
          <div className="right-side content">
            <div className="upper-part">
              <h3>AI-Enabled Remote Proctoring</h3>
              <p>
                Create and monitor a transparent and authentic assessment
                process using our AI-integrated system. We ensure fairness and
                prevent malpractice during the assessment process.
              </p>
            </div>
            <div className="lower-part">
              <h3>Kandid8 Database</h3>
              <p>
                Choose from a large selection of questions from many subject
                areas and add your own test content to create a multi-subject
                test in seconds.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="second-sect">
        <div className="left-half container">
          <h3>Downloadable Reports</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed justo
            platea a leo, nulla sit. Odio consequat vitae, malesuada odio
            mattis. Nibh magna vitae, a, eros, accumsan at elementum, commodo.
          </p>
        </div>
        <div className="right-half container">
          <h3>Customizable Assessments</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed justo
            platea a leo, nulla sit. Odio consequat vitae, malesuada odio
            mattis. Nibh magna vitae, a, eros, accumsan at elementum, commodo.
          </p>
        </div>
      </section>
      <section className="third-sect">
        <h2>Need to know more? We are here for you.</h2>
        <div className="card-container">
          <div className="card">
            <Link to="../support">
              <AiOutlineWechat className="icon" />

              <h2>FAQ</h2>
            </Link>
          </div>

          <div className="card">
            <Link to="../support">
              <RiCustomerService2Line className="icon" />

              <h2>SUPPORT</h2>
            </Link>
          </div>
        </div>
      </section>
      <section className="fourth-sect">
        <div className="text-container">
          <h2>
            A demo gives you a baseline for understanding <br />
            the modalities of our platform.
          </h2>

          <p>Let us take you through the process.</p>
        </div>
        <Link to="../contact">
          <Button text="Contact Us" className="contact-btn" />
        </Link>
      </section>
      <Footer />
    </div>
  );
}

export default Features;
