import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BsSearch } from "react-icons/bs";
import Footer from "./Footer";
import "./LandingPage.css";
import Button from "./Buttons/ButtonCustom.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Accordion from "./Accordion";

function Support(props) {
  const data1 = {
    title: "Examiner FAQ",
    rows: [
      {
        title: "Is it a free service?",
        content: `There is a Free Plan offering limited access to one Super Admin who is responsible for creating and removing content examiners.
        Assessment on this plan can only be sent to a maximum of 50 candidates.`,
      },
      {
        title:
          "How do I increase the number of candidates I can send an invite to? ",
        content:
          "Email Invites are sent immediately they are generated to all candidates.",
      },
      {
        title:
          "How do I increase the number of candidates I can send an invite to?",
        content: `You need to first upgrade from Free Plan to your preferred paid plan.`,
      },
      {
        title: "What is the package version",
        content: <p>current version is 1.2.1</p>,
      },
      {
        title: "Curabitur laoreet, mauris vel blandit fringilla",
        content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
            Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
            Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
      },
      {
        title: "What is the package version",
        content: <p>current version is 1.2.1</p>,
      },
      {
        title: "Curabitur laoreet, mauris vel blandit fringilla",
        content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
            Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
            Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
      },
      {
        title: "What is the package version",
        content: <p>current version is 1.2.1</p>,
      },
      {
        title: "Curabitur laoreet, mauris vel blandit fringilla",
        content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
            Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
            Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
      },
    ],
  };

  return (
    <div className="support">
      <header className="support-header">
        <div className="faq-search-bar">
          <h2>Do you have questions?</h2>
          <div className="search-box">
            <BsSearch className="search-icon" />
            <input
              placeholder="Search questions..."
              type="text"
              // onChange={(event) => handleSearch(event)}
            ></input>
          </div>
        </div>
      </header>
      <section className="faq">
        <div className="faq-box">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <div className="accordion-box">
            <Accordion />
          </div>
        </div>

        <h3 className="more-questn-title">Still have questions?</h3>
        <p>Feel free to reach out to us. We will respond to you shortly.</p>
        <Link to="../contact">
          <Button text="Contact Us" className="contact-btn" />
        </Link>
      </section>

      <Footer />
    </div>
  );
}

export default Support;
