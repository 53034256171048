import React from "react";
import Navbar from "./Navbar";
import "./LandingPage.css";
import Footer from "./Footer";

function Privacy(props) {
  return (
    <div className="privacy">
      <main className="privacy-content">
        <h2>Kandid8 Privacy Policy</h2>
        <p>
          Kandid8 a product of Techbeaver takes the privacy of all its
          stakeholders seriously. As such, we have set forth the following
          privacy policy:
        </p>
        <p>
          This privacy policy outlines the Policy adopted by Kandid8, relative
          to the collection, use, storage, and disclosure of personal
          information on the site and on all related websites, networks,
          applications, and other services provided by us, including the ID
          Verification and Proctoring Services System. Through this Policy, we
          attempt to make information about how we handle personal information
          available. If you do not accept the terms of this Policy, please do
          not use any of our Services.
        </p>
        <p>
          Please take the time to familiarize yourself with this Policy and if
          you have any questions, please contact us at
          <a href="mailto:suppoort@kandid8.com"> suppoort@kandid8.com</a> .
        </p>
        This Policy is incorporated into and is subject to our Terms of service
        .<h3>PROCTORING SERVICES SYSTEM</h3>
        <p>
          We provide Services on behalf of our customers. They may be an African
          institution, organization, company of which you are a member (in any
          form) or an applicant or at which you are enrolled. Customers are
          organizations, educational institutions, or companies that offer and
          control any exam that is used to make employment, certification,
          licensure, or other types of decisions based on exam results. We
          collect and/or receive personal information about a person taking an
          examination (“Candidate”) to develop, administer or proctor an exam
          for a customer. “Personal Information” is any information, such as
          name or email address, that identifies or can be used to identify the
          person to whom such information pertains or is associated with a
          person. While providing Personal Information is voluntary, it is
          necessary if you are to obtain the grades, exam scores, services, or
          credentials that you seek from your employer, organization,
          institution, or customer. We will handle and treat Personal
          Information collected or received while providing our Services in a
          manner that is consistent with this Policy and applicable laws.
        </p>
        <h3>WHAT INFORMATION WE COLLECT </h3>
        <p>
          We collect Personal Information that you provide through the use of
          our Services: We collect Personal Information from you when you
          voluntarily provide such information, such as when you: launch the
          Services from your learning management system; register for and/or
          login to the Proctoring Platform directly; request customer support, a
          demo, or more information; communicate with us via email or social
          media sites; provide biometric data including as part of onboarding to
          create your identity profile; and take an exam on our Services.
        </p>
        <p>
          Personal Information collected through our Services may include, but
          is not limited to, the following:
        </p>
        <ul>
          <li>Name, Addresses, Postal Code, Zip Code</li>
          <li>Image of identity document, such as a driver’s license</li>
          <li>Image of you</li>
          <li>Telephone Numbers Username and Password</li>
          <li>
            Name of the institution, organization, or company at which you are
            enrolled and course exam information
          </li>
          <li>Employment information</li>
          <li>E-mail address</li>
          <li>Exam submissions</li>
          <li>Screen-captures</li>
          <li>
            Audio and video recordings of you taking exams and the exam-room
            environment/background
          </li>
          <li>
            Government-issued identification number (if required, or as included
            in the identify document you provide) We may collect information
            about you from institutions, organizations, or companies, such as
            your email address, to provide you a registration link to our
            Services. This information may also be combined with the Personal
            Information collected from you.
          </li>
        </ul>
        <h3>YOUR PERSONAL INFORMATION</h3>
        <p>
          We use your Personal Information generally as a processor on behalf of
          institutions, organizations, and companies, to provide and improve our
          Services and to develop new services, functions, or features for our
          Services. For instance, we use your Personal Information to register
          you for an exam, answer any questions or concerns you may have,
          administer the exam, and/or convey the results of the exam. We may
          also use your Personal Information to contact you about scheduling,
          technical, security, billing, invoicing, order tracking or other exam
          related issues, or to contact you for other administrative purposes,
          such as customer service. We use biometric data we collect from you
          during onboarding to create your identity profile for the Services. We
          use biometric data we collect from you when you take an exam, using
          the Services to compare against your identity profile for purposes of
          verifying your identity.
        </p>
        <h3>DISCLOSING YOUR PERSONAL INFORMATION</h3>
        <p>
          As an exam taker, we provide your Personal Information to our Customer
          from whom you seek a test score, service, or credential to enable them
          to validate your identity and your exam score, as well as for academic
          hearing purposes. For further information about how your institution,
          organization or company may use your Personal Information, please
          contact your institution, organization or company.
        </p>
        <p>
          We may also disclose your Personal Information in the following
          situations:
        </p>
        <ul>
          <li>
            When we believe it is reasonably necessary to comply with laws and
            regulations, or in response to a subpoena, court order, or other
            legal processes.
          </li>
          <li>
            To protect against misuse or unauthorized use of our Services, or to
            protect the security or integrity of our Services or any facilities
            or equipment used to make our Services available.
          </li>
          <li>
            To limit our legal liability and protect our property or other legal
            rights, or the rights of our customers and exam takers.
          </li>
          <li>
            To address actual or suspected fraud or other illegal activities.
          </li>
        </ul>
        <p>
          We may also seek your consent for additional disclosures of
          information, including your Personal Information, and will share it
          only as described to you.
        </p>
        <h3>EXTERNAL WEBSITES AND THIRD PARTIES</h3>
        <p>
          From time to time, our Services may contain links to third party
          websites. The policies and procedures we described in this Policy do
          not apply to the third-party websites. The links from our Services do
          not imply that the Company endorses or has reviewed the third-party
          websites. We suggest contacting those sites directly for information
          on their privacy policies before providing any of your personal
          information to them.
        </p>
        <p>
          AWS (Amazon), GCP (Google), Azure (Microsoft) and WHC (Webhosting
          Canada) are our cloud service providers. All the Data stored on the
          cloud servers is encrypted and no data is accessible by or shared with
          these cloud service providers.
        </p>
        <p>
          We share information with third-party apps and websites that integrate
          with our API or Services, or those with an API or Service with which
          we integrate.
        </p>
        <h3>COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
        <p>
          We use various technologies to collect information, including cookies.
          A cookie is a small file placed onto your device. Across the web,
          cookies do lots of different jobs, like letting you navigate between
          pages efficiently, storing your preferences, and generally improving
          your experience on a website. Cookies make the interaction between you
          and the website faster and easier. If a website does not use cookies,
          it will think you are a new visitor every time you move to a new page
          on the site – for example, when you enter your login details and move
          to another page it will not recognize you, and it will not be able to
          keep you logged in.
        </p>
        <p>
          Our Services use cookies and similar technologies, such as web
          beacons, to identify your device and enable the functioning of our
          features, including the ability to log into your account,
          authentication, security, preferences retention, performance
          optimization, and data analytics.
        </p>
        <p>
          If you do not want to receive cookies, you can change your browser
          settings. If you use our Services without changing your browser
          settings, we will assume that you’ve agreed to receive all cookies on
          the Kandid8 website. Please note that our Services will not function
          properly without cookies.
        </p>
        <h3>DATA SECURITY</h3>
        <p>
          We use commercially reasonable technical, organizational, and
          administrative measures to protect our Services against unauthorized
          or unlawful access or processing and against accidental loss, theft,
          disclosure, copying, modification, destruction or damage.
        </p>
        <p>
          We take reasonable measures to protect your Personal Information in
          accordance with this Policy and applicable law. Nonetheless, the
          Internet cannot be guaranteed to be 100% secure, and we cannot ensure
          or warrant the security of any information you transmit to us or
          choose to store on the Services, and you do so at your own risk.
        </p>
        <p>
          You play an important role in keeping your information secure. You
          should not share your username, password, or other security
          information for your account with anyone. If you have reason to
          believe that your interaction with us is no longer secure please
          contact us immediately as detailed in the Contact Us section, below.
        </p>
        <h3>DATA INTEGRITY</h3>
        <p>
          We take reasonable steps to ensure that the data we collect is
          reliable for its intended use. However, it is your responsibility to
          update us with any relevant changes in your personal information so
          that we can provide you with our Services. We collect Personal
          Information that is relevant for the purposes for which it is to be
          used. Furthermore, we take reasonable steps to assure that we process
          personal information in a way that is consistent with the purposes for
          which it is collected.
        </p>
        <h3>DATA RETENTION AND DELETION </h3>
        <p>
          We retain information for as long as necessary to perform the Services
          described in this Policy, if necessary for us to fulfill a contract
          with you or your institution, or if needed to comply with our legal
          obligations. After the applicable retention period, we will
          automatically delete your Personal Information from the Platform and
          our systems.
        </p>
        <p>
          We provide our Services as a processor on behalf of our customers who
          have the right to receive your data. Our customers have their own
          policies with respect to data retention and deletion. For further
          information on such policies, to revoke consent related to any data,
          or to request deletion of any Personal Information outside of the
          retention policies described above, please contact the applicable
          institution, organization or company.
        </p>
        <h3>INTERNATIONAL USERS AND INTERNATIONAL DATA TRANSFER</h3>
        <p>
          Our Services are hosted in Nigeria and intended for users located
          within Nigeria and Africa. If you choose to use our Services from the
          European Union or other regions of the world with laws governing data
          collection and use that may differ from Nigerian law, then please note
          that you are transferring your Personal Information outside of those
          regions to Nigeria for storage and processing. Also, we may transfer
          your data from Nigeria to other countries or regions in connection
          with storage and processing of data, fulfilling your requests, and
          operating the Services. By providing your information on or to the
          Services, you consent to any such transfer, storage, and processing in
          accordance with this Policy.
        </p>
        <h3>THE CHOICES YOU HAVE WITH YOUR INFORMATION</h3>
        <p>
          If you created an account with us in connection with your use of the
          Services, you may modify or update some of your Personal Information
          by logging in and accessing your profile. You should be aware,
          however, that it is not always possible to completely remove or modify
          information in our databases. If you wish to access or modify any
          other Personal Information that we hold about you, you may contact us
          at <a href="mailto:suppoort@kandid8.com">suppoort@kandid8.com</a>.
        </p>
        <p>
          If you have not created an account with us in connection with your use
          of the Services and wish to access or modify any Personal Information
          that we hold about you, please contact the applicable institution,
          organization, company.
        </p>
        <h3>INFORMATION FROM CHILDREN</h3>
        <p>
          Our Services are not directed to children under the age of 13. We do
          not knowingly collect, maintain, or use personally identifiable
          information from children under the age of 13. We request that all
          visitors to our Site who are under 13 years of age not disclose or
          provide any Personal Information.
        </p>
        <p>
          We encourage parents and legal guardians to monitor their children’s
          Internet usage and to help enforce our Privacy Policy by instructing
          their children never to provide Personal Information on our Services
          without their permission. If we discover that a child under 13 years
          of age has provided us with Personal Information, we will take steps
          to delete such information.
        </p>
        <h3>CHANGES TO THIS POLICY</h3>
        <p>
          Kandid8 reserves the right to change this Policy from time to time by
          posting an updated Policy to this site and the “last updated date” at
          the top of this page will be updated. We may also provide you
          additional notice, such as adding a statement to the home screen or
          sending you an email notification. Please review this Policy
          periodically, and especially before you provide any Personal
          Information. Your continued use of the Services after any changes or
          revisions to this Policy shall indicate your agreement with the terms
          of such revised Policy.
        </p>
        <h3>CONTACT US</h3>
        <p>
          Please contact us with any questions or comments you have about this
          Policy, your information, our use and disclosure practices, or our
          Services by email at{" "}
          <a href="mailto:suppoort@kandid8.com">suppoort@kandid8.com</a>. You
          can view the complete Terms of Service here.
        </p>
      </main>

      <Footer />
    </div>
  );
}

export default Privacy;
