import React from "react";
import Button from "./Buttons/ButtonCustom.js";
import { Link } from "react-router-dom";
import woman from "../Images/woman.png";
import manSmiling from "../Images/man-smiling.png";
import "./LandingPage.css";

function RequestDemo() {
  return (
    <div className="request_demo">
      <div className="request_cont">
        <div className="right_side">
          <div className="left_image">
            <div className="outline-1"></div>
            <img src={woman} alt="woman" className="image1" />
          </div>
          <div className="right_image">
            <img src={manSmiling} alt="manSmiling" className="image2" />
            <div className="outline-2"></div>
          </div>
        </div>
        <div className="left_side">
          <div className="left-content">
            <h3 className="title">
              <i>Zero</i> Bias. <br />
              <i>Seamless</i> Solution.
            </h3>
            <p>
              A demo session gives you a baseline for understanding the
              modalities of our platform.
            </p>

            <Link to="requestdemo">
              <Button text="Schedule A Demo" className="request-btn" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDemo;
