import React, { useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiChevronDown, FiMinus } from "react-icons/fi";
import { BsColumns } from "react-icons/bs";

const AccordionSection = styled.div`
  display: block;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  font-size: 0.5rem;
  align-items: center;
  height: 100%;
`;

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 400;
  color: #3c0174;
  text-align: left;
  margin-bottom: 15px;
  padding-top:20px;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
    height: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  top: 10%;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
`;
const Wrap = styled.div`
  bacground: #3c0174;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px 0px;
  display: flex;
  width: 100%;
  height: 60px;
  flex: 1;
  text-align: left;
  cursor: pointer;
  border-top: 0.4px solid #3c0174;

  h2 {
    font-size: 20px;
    font-weight: 400;
    color: black;
  }
  span{
    font-size:2.2vw;
    padding:0px;
  }

  @media screen and (max-width: 1090px) {
    height:55px;
    h2{
      font-size: 1.7vw;
      text-align:left;
      height:100%;
      width: 90%;
  }

  

  @media screen and (max-width: 991px) {
h2{
      font-size: 1.6vw;}
  }

  @media screen and (max-width: 768px) {
    height:50px;
    h2{
      font-size: 2.3vw;
      text-align:left;
      height:100%;
      width:85%;
  }


  @media screen and (max-width: 425px) {
     height:40px;
    h2{
      font-size: 3vw;
      text-align:left;
      height:100%;
      width:85%;
  }

`;

const Dropdown = styled.div`
  padding: 20px 50px 10px 50px;
  font-size: 16px;
  text-align: left;

  p {
    font-size: 1.489vw;
  }

  li {
    font-size: 1.2vw;
    text-align: left;
    list-style-type: square;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 20px;
    font-size: 2.5vw;
    p {
      font-size: 5vw;
    }

    li {
      font-size: 3vw;
      text-align: left;
      list-style-type: square;
    }
  }
`;

const Accordion = () => {
  const ExaminerFaq = [
    {
      title: "Examiner FAQ",
      question: "Is it a free service?",
      answer:
        "There is a Free Plan offering limited access to one Super Admin who is responsible for creating and removing content examiners. Assessment on this plan can only be sent to a maximum of 50 candidates.",
    },
    {
      question:
        "How long does it take for Assessment Invite Links to get to Candidates ",
      answer:
        "Email Invites are sent immediately they are generated to all candidates.",
    },
    {
      question:
        "How do I increase the number of candidates I can send an invite to? ",
      answer:
        "<ul><li>Kindly Login and click on <b>Plans and Pricing</b> in Settings.</li><li>Select your preferred plan and preferred payment method, enter the required details and click <b>Continue</b></li><li>Your candidate invite list will be automatically upgraded.</li></ul>",
    },
    {
      question: "I want to pay to the bank. What is your bank account details?",
      answer:
        "<p>To subscribe to a paid plan, you must follow this process:</p><ul><li> Log in with your Kandid8 account username and password. If you don't have a Kandid8 account, you must register to obtain one. Registration is free.</li><li>Click on <b>Plans and Pricing</b> in Settings.</li><li>Select <b>Pay Cash to Bank</b> as your preferred payment method and click <b>Continue.</b></li><li>Our bank account information and follow-up instructions will be displayed.</li></ul>",
    },
    {
      question:
        "How long do I have to wait after payment to begin using the plan?",
      answer:
        "Your account would be instantly upgraded if subscription is completed via debit/ATM card (Interswitch Verve, Mastercard or Visa). If you pay by bank deposit or online bank transfer, you would get access within 1 - 8hrs. We accept Naira debit cards from Nigerian banks only.",
    },
    {
      question:
        "How do I ensure that only invited candidates have access to the Assessment?",
      answer:
        "All Invited Candidates would be required to enter a One Time Password to be granted access to the Assessment.",
    },
    {
      question: "What if my Candidates cheat while taking the Assessment?",
      answer:
        "Kandid8 offers AI-enabled remote proctoring that monitors the Candidate's environment, number of tabs opened and sounds generated while taking the Assessment and generates a downloadable report on each Candidate after the Assessment.",
    },
    {
      question: "Will my debit card details be secure if used for payment?",
      answer:
        "Our online payment option is based on Interswitch, e-tranzact and VoguePay payment processors which are recognized widely as being safe and secure payment solutions.",
    },
    {
      question: "Which forms of payment do you accept?",
      answer:
        "<p>We accept payments by:</p> <ul><li>Online payment by naira debit/ATM cards (Interswitch Verve, Visa, Mastercard). This is the fastest means of payment and gives you instant access to your preparation pack. We use leading payment processors Interswitch, e-tranzact and Voguepay and the process is safe and secure.</li><li>ank transfer.</li><li>Cash deposit into our bank account.</li></ul>",
    },
    {
      question: "Are you related to any private/public organization?",
      answer:
        "No. Kandid8 is a product of an independent company. However, we work with Human Resources specialists to develop and offer the right preparation method for job seekers. Having these contacts is crucial for us to make sure that our preparation materials are aligned on the aptitude tests used during recruitment processes.",
    },
  ];
  const CandidateFaq = [
    {
      title: "Candiddate FAQ",
      question:
        "Will your practice assessments help if I have only one or two days before an Assessment?",
      answer:
        "Yes, getting some practice is always helpful. However, having more practice time is a good idea. If you have only one day to prepare, you may wish to check out the option of postponing the Assessment. This is possible with some employers.",
    },
    {
      question: "How can I access a practice Assessment?",
      answer:
        "<ul><li> Log in with your Kandid8 account username and password. If you don't have a Kandid8 account, you must register to obtain one. Registration is free.</li><li>Click on <b>Plans and Pricing</b> in Settings.</li><li>Select <b>Pay Cash to Bank</b> as your preferred payment method and click <b>Continue.</b></li><li>Once payment is confirmed, you have access to your practice test.</li></ul>",
    },
    {
      question: "Will you send me books or paper documents?",
      answer:
        "No. Our preparation packages are only available on our website, accessed through a username and password.",
    },
    {
      question: "Will my debit card details be secure if used for payment?",
      answer:
        "Our online payment option is based on Interswitch, e-tranzact and VoguePay payment processors which are recognized widely as being safe and secure payment solutions.",
    },
    {
      question:
        "I have slow internet connection. Can I take your online practice assessments?",
      answer:
        "Our online practice tests are designed to suit both high- and slow-speed Internet connections, so, you will not be disadvantaged by having a slow Internet connection.",
    },
    {
      question: "Can I take your practice assessments more than once?",
      answer:
        "Yes, you can take the test as many times as you need to practice, gain confidence and improve your results.",
    },
    {
      question: "Which forms of payment do you accept?",
      answer:
        "<p>We accept payments by:</p> <ul><li>Online payment by naira debit/ATM cards (Interswitch Verve, Visa, Mastercard). This is the fastest means of payment and gives you instant access to your preparation pack. We use leading payment processors Interswitch, e-tranzact and Voguepay and the process is safe and secure.</li><li>ank transfer.</li><li>Cash deposit into our bank account.</li></ul>",
    },
    {
      question:
        "Are your practice assessments similar to those I'll receive in the real assessment?",
      answer:
        "Our practice assessments are the closest you can get to the real thing. They are based on past questions and questions designed from careful review of an employer's testing history.",
    },
    {
      question: "What makes Kandid8's preparatory material better than others?",
      answer:
        "We provide customized preparatory material for all assessments. This is the product of extensive review and analysis of the substance and style of their past assessments. We provide detailed answer explanations for each question. Reading these explanations is a critical component of your preparation. It will allow you to quickly and effectively approach similar questions in the actual assessment.",
    },
    {
      question: "Can I get free samples of the Practice Materials?",
      answer:
        "Yes, we have free sample of aptitude tests on our website. Login with your registered email address and password and you would find a free sample practice pack.",
    },
    {
      question: "Can I share my Kandid8 account with a friend?",
      answer:
        "No. Each account is linked to an individual person. For safety reason, our security system will not accept two connections at the same time on the same account from different computers.",
    },
  ];

  const [openAccordionA, setOpenAccordionA] = useState(false);
  const [openAccordionB, setOpenAccordionB] = useState(false);

  const toggleAccordionA = (index) => {
    if (openAccordionA === index) {
      return setOpenAccordionA(null);
    }
    setOpenAccordionA(index);
  };
  const toggleAccordionB = (indx) => {
    if (openAccordionB === indx) {
      return setOpenAccordionB(null);
    }
    setOpenAccordionB(indx);
  };
  return (
    <IconContext.Provider value={{ color: "#3c0174" }}>
      <AccordionSection>
        <Container>
          {ExaminerFaq.map((item, index) => {
            return (
              <>
                <HeaderText>{item.title}</HeaderText>
                <Wrap onClick={() => toggleAccordionA(index)} key={index}>
                  <h2>{item.question}</h2>
                  <span>
                    {openAccordionA === index ? <FiMinus /> : <FiChevronDown />}
                  </span>
                </Wrap>
                {openAccordionA === index ? (
                  <Dropdown>
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
        <br />
        <br />
        <Container>
          {CandidateFaq.map((data, indx) => {
            return (
              <>
                <HeaderText>{data.title}</HeaderText>
                <Wrap onClick={() => toggleAccordionB(indx)} key={indx}>
                  <h2>{data.question}</h2>
                  <span>
                    {openAccordionB === indx ? <FiMinus /> : <FiChevronDown />}
                  </span>
                </Wrap>
                {openAccordionB === indx ? (
                  <Dropdown>
                    <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
};

export default Accordion;
