import React from "react";
import "./LandingPage.css";
import Button from "./Buttons/ButtonCustom.js";
import Logo from "../Images/Logo2.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

function Navbar(props) {
  return (
    <>
      <div className="navbar">
        <div className="hero_logo">
          <Link to="/">
            <img src={Logo} alt="logo2" />
          </Link>
        </div>
        <nav className="nav_content">
          <ul>
            <li className="list_item">
              Features
              <RiArrowDropDownLine className="arrow" />
              <div className="dropdown-content">
                <h4>Solutions</h4>
                <a href="http://kandid8.com/#:~:text=of%20malpractice.-,AI">
                  AI-Enabled Remote Proctoring
                </a>
                <a href="http://kandid8.com/#:~:text=just%20one%20click.-,Customizable">
                  Customizable Assessments
                </a>
                <a
                  href="http://kandid8.com/#:~:text=at%20your%20convenience.-,Question%20Bank
                "
                >
                  Bulk Questions Upload
                </a>
                <a href="#">Kandid8 Database</a>
                <a href="http://kandid8.com/#:~:text=the%20assessment%20process.-,Downloadable,-">
                  Downloadable Reports
                </a>
                {/* <h4>Support</h4>
                <a href="#">24-Hour Support</a>
                <a href="#">Email Alerts</a> */}
              </div>
            </li>

            {/* <li className="list_item">
              Resources
              <RiArrowDropDownLine className="arrow_down" />
              <div class="dropdown-content">
                <a href="#">Kandid8 Blog</a>
                <a href="#">Ebooks and Guides</a>
              </div>
            </li> */}
            <li className=" list_item no-dropdown">
              <Link to="../pricing" className=" no-dropdown-link pricing-link">
                Pricing
              </Link>
            </li>
            {/* <li className="list_item no-dropdown">
              <Link to="./contact" className="no-dropdown-link contact-link">
                Contact Us
              </Link>
            </li> */}

            <li className=" list_item">
              <a href="http://dev.kandid8.com/examiner-sign-up" target="_blank">
                <Button text="SIGN UP" className="request-btn" />
              </a>
            </li>

            <li className=" list_item">
              <a href="http://dev.kandid8.com/examiner-sign-in" target="_blank">
                <Button text="LOGIN" className="login-btn" />
              </a>
            </li>
          </ul>
          <div className="menu_Icon" onClick={props.click}>
            <FaBars />
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
