import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import "./LandingPage.css";
import { Link } from "react-router-dom";

function SideNav(props) {
  const [sideNavClass, setsideNavClass] = useState(props.sidenav);

  const closeNav = (e) => {
    e.preventDefault();

    setsideNavClass("side_nav_close");
  };

  return (
    <div>
      <div id="side_nav" className={sideNavClass}>
        <a href="javascript:void(0)" class="closebtn" onClick={props.click}>
          <MdClose />
        </a>
        <nav className="sidenav_content">
          <ul>
            <li className="sidenav_list_item">
              Features
              <RiArrowDropDownLine className="arrow" />
              <div className="drop-down-content">
                <h4>Solutions</h4>
                <a href="http://kandid8.com/#:~:text=AI%2DEnabled%20Remote,the%20assessment%20process.">
                  AI-Enabled Remote Proctoring
                </a>
                <a href="http://kandid8.com/#:~:text=Customizable%20Assessments,at%20your%20convenience.">
                  Customizable Assessments
                </a>
                <a href="http://kandid8.com/#:~:text=Question%20Bank,test%20in%20seconds.">
                  Bulk Questions Upload
                </a>
                <a href="#">Kandid8 Database</a>
                <a href="http://kandid8.com/#:~:text=Downloadable%20Results%20and,just%20one%20click.">
                  Downloadable Reports
                </a>
                <h4>Support</h4>
                <a href="#">24-Hour Support</a>
                <a href="#">Email Alerts</a>
              </div>
            </li>
            <li className="sidenav_list_item pricing" onClick={props.click}>
              <Link to="./pricing" className="pricin_link">
                Pricing
              </Link>
            </li>
            <li className="sidenav_list_item">
              <Link to="./requestdemo">
                <button className="request_btn" onClick={props.click}>
                  REQUEST DEMO
                </button>
              </Link>
            </li>
            <li className="sidenav_list_item">
              <a href="http://dev.kandid8.com/examiner-sign-in">
                <button className="login_btn" onClick={props.click}>
                  LOG IN
                </button>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default SideNav;
