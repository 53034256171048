import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import contactimage from "../Images/contactimage.png";

function ContactUsPage(props) {
  return (
    <div className="contact-page">
      <main className="contact-main">
        <h1>CONTACT US</h1>
        <div className="main-container">
          <div className="left-side">
            <img
              src={contactimage}
              alt={contactimage}
              style={{ width: "95%" }}
            />
          </div>
          <div className="right-side">
            <form className="contact-form">
              <div className="input-group">
                <label>
                  Name*
                  <input type="text" placeholder="Username" name="name" />
                </label>
              </div>
              <div className="input-group">
                <label>
                  Email*
                  <input type="email" placeholder="Email" name="email" />
                </label>
              </div>
              <div className="input-group message-box">
                <label>
                  Message Body
                  <textarea placeholder="Drop a message..." name="message" />
                </label>
              </div>

              <button className="contact-submit-btn" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </main>
      <Footer className="footer" />
    </div>
  );
}

export default ContactUsPage;
