import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { AiOutlineWechat } from "react-icons/ai";
import { RiCustomerService2Line } from "react-icons/ri";
import tickbox from "../Images/tickbox.png";
import ButtonCustom from "./Buttons/ButtonCustom";
import check from "../Images/check.png";
import { Link } from "react-router-dom";

function pricing(props) {
  return (
    <div className="pricing">
      <main className="main-container">
        <section className="subscription-categories">
          <h1>
            Flexible and affordable <br />
            pricing For every organization
          </h1>
          <div className="cards">
            <div className="card-group free">
              <div className="border"></div>
              <div className="card-content-header">
                <h5>Free</h5>

                <div>
                  <h5>$0/</h5>
                  <p>Month</p>
                </div>
              </div>

              <h5>Features</h5>
              <p>This plan is default for all users</p>

              <hr />

              <div className="plan-content">
                <ul className="card-list">
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>1 Super Examiners</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>50 Candidates Max</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Access to Kandid8 Database Questions</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Send automated assessment link</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Filtered Assessment Reports</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Candidate Assessment Psychology Report</p>
                  </li>
                </ul>
              </div>

              <ButtonCustom className="pricing-btn " text="GET STARTED" />
            </div>

            <div className="card-group professional">
              <div className="border"></div>
              <div className="card-content-header">
                <h5>Professional</h5>
                <div>
                  <h5>$20/</h5>
                  <p>Month</p>
                </div>
              </div>
              <h5>Features</h5>
              <p>14-Day free trial available</p>
              <hr />
              <div className="plan-content">
                <ul className="card-list">
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>5 Super Examiners</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>200 Candidates Max</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Create Assessment with Custom Questions</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Access to Kandid8 Database Questions</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />

                    <p>Send Scheduled Assessment Invite via Email</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Send automated assessment link</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>AI-Enabled Remote Proctored Assessment</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p> Candidate Assessment Psychology Report</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Downloadable Filtered Reports</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Automatic Grading</p>
                  </li>
                </ul>
              </div>
              <ButtonCustom className="pricing-btn " text="GET STARTED" />
            </div>
            <div className="card-group entreprise">
              <div className="border"></div>
              <div className="card-content-header">
                <h5>Entreprise</h5>
                <div>
                  <h5>$100/</h5>
                  <p>Month</p>
                </div>
              </div>
              <h5>Features</h5>
              <p>14-Day free trial available</p>
              <hr />
              <div className="plan-content">
                <ul className="card-list">
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Everything in Professional, plus</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Unlimited super examiner access</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Customized notifications</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Long schedule periods</p>
                  </li>
                  <li className="card-list-item">
                    <img src={tickbox} alt={tickbox} />
                    <p>Third party integrations</p>
                  </li>
                </ul>
              </div>
              <ButtonCustom className="pricing-btn " text="GET STARTED" />
            </div>
          </div>
        </section>
        <section className="compare-features-table">
          <h2>Compare Features</h2>
          <div className="compare-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h3>Stater</h3>
                    <p>Free</p>
                  </th>
                  <th>
                    <h3>Professional</h3>
                    <p>$20/ month</p>
                  </th>
                  <th>
                    <h3>Entreprise</h3>
                    <p>$100/ month</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="assessment-row">
                  <td>Assessments and Reports</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Super Examiners</td>
                  <td>1</td>
                  <td>5</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Number of candidates</td>
                  <td>50</td>
                  <td>200</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Assessment Invite via generated auto link</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Assessment from kandid8 database</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Create custom questions</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Send schedule invite via email</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Report on Candidate Result</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Al enabled remote proctored assessment </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Candidate Assessment Psychology report </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>View filtered report</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Downloadable Filtered Reports</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Automatic Grading</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>

                <tr className="candidate-row">
                  <td>Candidate </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td>Assessment invite</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Assessment completion</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Notification for successful submission </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr className="notification-row">
                  <td>Notification</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="sub-notification-row">
                  <td>IN APP NOTIFICATION </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Updated company details</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Delivered and Opened invite </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Timestamp on generated link</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>
                    When link is live and candidates can start taking assessment{" "}
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>When the generated link has expired </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>The number of candidates who took the assessment </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr className="sub-notification-row">
                  <td>EMAIL NOTIFICATION</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>When the assessment report is ready</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>
                    when candidate is absent or does not complete assessment{" "}
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>A welcome email </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Confirmation for generated auto link</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Generated Reports </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Notifying Examiner on Subscription Renewal</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr className="support-row">
                  <td>Support</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Tooltips/ Customer Support</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>24 Hour Support</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td>Recruiter contacts the Kandid8 support team</td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                  <td>
                    <img src={check} alt={check} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <ButtonCustom className="pricing-btn " text="GET STARTED" />
                  </td>
                  <td>
                    <ButtonCustom className="pricing-btn " text="GET STARTED" />
                  </td>
                  <td>
                    <ButtonCustom className="pricing-btn " text="GET STARTED" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section className="contact-us">
          <h2>Need to know more? <br/>We are here for you.</h2>
          <div className="card-container">
            <div className="card">
              <AiOutlineWechat className="icon" />

              <Link to="../support">
                <h2>FAQ</h2>
              </Link>
            </div>
            <div className="card">
              <RiCustomerService2Line className="icon" />
              <Link to="../support">
                <h2>SUPPORT</h2>
              </Link>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default pricing;
