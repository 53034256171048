import React from "react";
import { Link } from "react-router-dom";
import Button from "./Buttons/ButtonCustom.js";
import "./LandingPage.css";
import Navbar from "./Navbar";
import NewHero from "../Images/NewHero.png";

function Hero(props) {
  return (
    <>
      <div className="hero">
        <div className="hero_container">
          <main className="hero_content">
            <div className="hero-right content">
              <h1>
                Build a
                <span className=" better">
                  <i> Better</i>
                </span>
                <br />
                Workforce
              </h1>

              <p>
                Let's help you get the best talent always.
                <br />
                Our potential- driven assessment helps get the right and best
                talent for your business.
              </p>
              <div className="content-btn-container">
                <li>
                  <Link to="./requestdemo">
                    <Button className=" login-btn" text="REQUEST DEMO">
                      {props.text}
                    </Button>
                  </Link>
                </li>
                <li>
                  <a
                    href="http://dev.kandid8.com/dashboard/dashboard-overview"
                    target="_blank"
                  >
                    <Button className="request-btn" text="GET STARTED">
                      {props.text}
                    </Button>
                  </a>
                </li>
              </div>
            </div>
            <div className="hero-left">
              <img
                src={NewHero}
                alt={NewHero}
                style={{ width: "100%" }}
                className="left-image"
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Hero;
