import React from "react";
import "./LandingPage.css";
import Logo from "../Images/Logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_logo">
          <Link to="../">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="footer_content">
          <div className="content_item">
            <h1 className="cont_title">Company</h1>
            <ul className="footer_cont_list">
              <li className="cont_list_item">About Us</li>
              <li className="cont_list_item">Team</li>
              <li className="cont_list_item">Careers</li>
            </ul>
          </div>
          <div className="content_item legal-link">
            <h1 className="cont_title">Legal</h1>
            <ul className="footer_cont_list">
              <Link to="../privacy">
                <li className="cont_list_item">Privacy Policy</li>
              </Link>
              <Link to="../privacy">
                <li className="cont_list_item">Cookie Policy</li>
              </Link>
              <Link to="../tos">
                <li className="cont_list_item">Terms of Service</li>
              </Link>
            </ul>
          </div>

          <div className="content_item support-link">
            <Link to="../support">
              <h1 className="cont_title">Support</h1>
            </Link>
            <ul className="footer_cont_list">
              <Link to="../support">
                <li className="cont_list_item">Help Center</li>
              </Link>
              <Link to="../support#:~:text=Frequently%20Asked%20Questions-">
                <li className="cont_list_item">Candidate FAQ</li>
              </Link>
              <Link to="../support#text=vel%20blandit%20fringilla-">
                <li className="cont_list_item">Examiner FAQ</li>
              </Link>
            </ul>
          </div>

          <div className="sm_Icons">
            {/* <div className="fa-stack fa-1x">
              <a href="#" rel="noopener noreferrer">
                <i className="fa fa-circle fa-stack-2x icon-background1"></i>
                <i className="fa-brands fa-facebook-f fa-stack-1x "></i>
              </a>
            </div> */}
            <div className="fa-stack fa-1x">
              <a
                href="https://instagram.com/kandid8_technologies"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-circle fa-stack-2x icon-background1"></i>
                <i className="fa-brands fa-instagram fa-stack-1x"></i>
              </a>
            </div>
            <div className="fa-stack fa-1x">
              <a
                href="https://twitter.com/kandid8_tech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-circle fa-stack-2x icon-background1"></i>
                <i className="fa-brands fa-twitter fa-stack-1x"></i>
              </a>
            </div>
            <div className="fa-stack fa-1x">
              <a
                href="https://www.linkedin.com/company/kandid8-ltd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-circle fa-stack-2x icon-background1"></i>
                <i className="fa-brands fa-linkedin-in fa-stack-1x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
