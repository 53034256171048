import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequestDemoPage from "./RequestDemoPage";
import DocumentMeta from "react-document-meta";
import "bootstrap/dist/css/bootstrap.min.css";
import Support from "./Support";
import Features from "./Features";
import ContactUsPage from "./ContactUsPage";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import Errorpage from "./Errorpage";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import ChatwootWidget from "./ChatwootWidget";
import LandingPage from "./LandingPage";
import TermsOfService from "./TermsOfService";
import "./LandingPage.css";
import ScrollToTop from "./ScrollToTop";

function App(props) {
  const [sideNavOpen, setsideNavOpen] = useState(false);

  const meta = {
    title: "Kandid8",
    description:
      "Kandid8 is an HR solution built for African enterprises to attract, engage and retain the right Talents. Our solution is built to create a seamless process for HR professionals from onboarding new talents to retirement or exit of this talents. We are incorporating AI also to assist organizations make the best decisions in engaging their workforce.",
    canonical: "http://kandid8.com",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Kandid8,Examination,HR,Test,Human Resources Human Resources management,Assessment,Applicant Tracking,Benefits Administration,Online learning and Authoring,Performance management,Payroll Management,Recruitment Management,Leave Management,HR Outsourcing,Timesheet Management,Appraisals,Talent Development,Complaint Management,Whistle blowing,Exit management,Recruiting,Hiring,Procurement,Compensation,Training",
      },
    },
  };

  const openNav = () => {
    setsideNavOpen(!sideNavOpen);
    // if (!sideNavOpen) {
    //   setsideNavOpen(true);
    // }
    // console.log(sideNavOpen);
  };


  //   let sideNav;

  //   if (sideNavOpen) {
  //     sideNav = <SideNav sidenav="side_nav" />;
  //   }
  return (
    <>
      {/* <DocumentMeta {...meta}> */}
        <Router>
          <ScrollToTop>
            <Navbar click={openNav} />
            {sideNavOpen && <SideNav sidenav="side_nav" click={openNav} />}
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/requestdemo" element={<RequestDemoPage />} />
              <Route path="/support" element={<Support />} />
              <Route path="/features" element={<Features />} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/404" element={<Errorpage />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/tos" element={<TermsOfService />} />
            </Routes>
          </ScrollToTop>
        </Router>
      {/* </DocumentMeta> */}
    </>
  );
}

export default App;
