import React, { useState, useMemo } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./LandingPage.css";
import countryList from "react-select-country-list";
import { Col, Row, Container } from "react-bootstrap";
import Select from "react-select";
import Button from "./Buttons/ButtonCustom.js";
import { Link } from "react-router-dom";
import axios from "axios";

function RequestDemoPage(props) {
  const [userDetails, setUserdetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    phoneNumber: "",
    companyName: "",
    jobTitle: "",
    serviceReported: "Request Demo",
    feedbackType: "REQUEST_DEMO",
  });

  const [formErrors, setFormErrors] = useState({});
  const [value, setValue] = useState("");
  const [submitForm, setSubmitForm]= useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
    setUserdetails((prevDetails) => {
      return { ...prevDetails, country: value.label };
    });
  };

  const style = {
    control: (base, state) => ({
      ...base,
      color: "black",
      border: state.isFocused ? 0 : 0,
      width: "100%",
      background: "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  const handleChange = (event) => {
    setUserdetails((prevDetails) => {
      return { ...prevDetails, [event.target.name]: event.target.value };
    });
  };

  console.log(userDetails);

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(userDetails));


    console.log(userDetails);

    axios
      .post("https://api.kandid8.com/api/v1/feedback/request-demo", userDetails)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validate = (values) => {
    const errors = {};

    const regex =
      /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/;

    if (!regex.test(values.username)) {
      errors.email = "Enter a valid email address";
    }
    return errors;
  };

  return (
    <div className="request_demo_page">
      <div className="main_content">
        <h1 className="req_title">Request a Demo</h1>
        <p className="instruction">
          All fields are required unless noted as optional.
        </p>
        {/* <pre>{JSON.stringify(userDetails, undefined, 2)}</pre> */}

        <form className="req-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="container">
            <div className="row ">
              <div className="col-md-6">
                <input
                  value={userDetails.firstName}
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="input"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  value={userDetails.lastName}
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="input"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <input
                  value={userDetails.email}
                  type="email"
                  name="email"
                  placeholder="Work Email Address*"
                  className="input"
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <p>

                {formErrors}
              </p> */}
            </div>
            <div className="row ">
              <div className="col-md-4">
                <div className="input select">
                  <Select
                    value={value}
                    onChange={changeHandler}
                    styles={style}
                    className="select-country"
                    classNamePrefix="react-select"
                    name="country"
                    options={options}
                    placeholder="Select a country"
                    required
                  ></Select>
                </div>
              </div>
              <div className="col">
                <input
                  value={userDetails.phoneNumber}
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number*"
                  className="input"
                  onChange={handleChange}
                  required
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <input
                  value={userDetails.companyName}
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  className="input"
                  onChange={handleChange}
                  required
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <input
                  value={userDetails.jobTitle}
                  type="text"
                  name="jobTitle"
                  placeholder="Job Title*"
                  className="input"
                  onChange={handleChange}
                  required
                ></input>
              </div>
            </div>
            <div className="last-row">
              <div className="flex">
                <div className="flex_left">
                  <p>
                    By submitting this form, you agree to our
                    <a> privacy policy</a>.
                  </p>
                </div>
                <div className="flex_left">
                  <p>
                    Need any assistance?{" "}
                    <Link to="../contact">Contact us.</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button className="req_btn">REQUEST A DEMO</button>
        </form>
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default RequestDemoPage;
