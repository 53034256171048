import React from 'react'
import Navbar from './Navbar';
import ErrorImage from '../Images/404.png'
import ButtonCustom from './Buttons/ButtonCustom';
import { Link } from 'react-router-dom';

function Errorpage() {
  return (
    <div className="error-page">
      <main className="error-container">
        <div>
          <img src={ErrorImage} alt={ErrorImage} style={{ width: "28%" }} />
        </div>
        <div>
          <h3>404</h3>
          <p>Page Not Found!</p>
          <Link to="../">
            <ButtonCustom className="error-btn" text=" < Return Home" />
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Errorpage;