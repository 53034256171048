import React from "react";
import "./LandingPage.css";
import { GiWallet } from "react-icons/gi";
import { GiGears } from "react-icons/gi";
import { MdOutlineListAlt } from "react-icons/md";
import { MdReduceCapacity } from "react-icons/md";

function AboutUs() {
  return (
    <div className="about-us">
      <div className="abt-content">
        <h3>
          Why
          <span className="choose">
            <i>Choose</i>
          </span>
          Us?
        </h3>
        <p className="no-breaks">
          We are your one true partner to hiring the best fit for the job.
          Kandid8 helps you go beyond the CVs to test your prospective employees
          remotely without fear of
          <i className="italic"> malpractice</i>.
        </p>
        <div className="about-cards">
          <div className="card-item">
            <div className="fa-stack fa-2x">
              <i class="fa fa-circle fa-stack-2x icon-background"></i>
              <GiGears className="icon" />
            </div>
            <h6>AI-Enabled Remote Proctoring</h6>
            <p>
              Create and monitor a transparent and authentic Assessment process
              using our AI-Integrated system.We ensure fairness and prevent
              malpractice during the assessment process.
            </p>
          </div>
          <div className="card-item">
            <div className="fa-stack fa-2x">
              <i class="fa fa-circle fa-stack-2x icon-background"></i>
              <MdOutlineListAlt className="icon" />
            </div>
            <h6>Downloadable Results and Reports</h6>
            <p>
              Download reports and extract scores for multiple candidates on
              each assessment. Our analytics system creates seamless and well
              analyzed reports to guide your decision making in just one click.
            </p>
          </div>
          <div className="card-item">
            <div className="fa-stack fa-2x">
              <i class="fa fa-circle fa-stack-2x icon-background"></i>
              <MdReduceCapacity className="icon" />
            </div>
            <h6>Customizable Assessments</h6>
            <p>
              Upload customized Assessments that are tailored to identify the
              best fit for that vacancy. With well laid out samples you can
              create a perfect Assessment at your convenience.
            </p>
          </div>
          <div className="card-item">
            <div className="fa-stack fa-2x">
              <i class="fa fa-circle fa-stack-2x icon-background"></i>
              <GiWallet className="icon" />
            </div>
            <h6>Question Bank</h6>
            <p>
              Choose from a large selection of questions from many subject areas
              and add your own test content to create a multi-subject test in
              seconds.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="shape">
        <p></p>
      </div> */}
    </div>
  );
}

export default AboutUs;
