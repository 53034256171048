import React from "react";
import Navbar from "./Navbar";
import "./LandingPage.css";
import Footer from "./Footer";

function TermsOfService(props) {
  return (
    <div className="t-o-s">
      <div className="nav_bar_container">
        <Navbar click={props.click} />
      </div>
      <main className="tos-content">
        <div className="header-text">
          <h1>Kandid8 Terms of Service</h1>
          <p>
            <i>Last updated on 2022-01-31</i>
          </p>
        </div>

        <h4>1. Information About Us</h4>
        <p>
          <ol>
            <li>
              kandid8.com (“Site”) is a website operated by TechBeaver
              (“TechBeaver”, “we”, “our” or “us”), a company registered in
              Palmgroove,Nigeria and under company number 2867508 whose
              registered address is at 15, Ibiyemi Street, Plamgroove, Shomolu,
              Lagos, Nigeria.
            </li>

            <li>
              These terms of service (“Terms of Service”), together with the
              documents referred to herein, set out the terms upon which you may
              use our Service (as defined below), and form a legally binding
              contract between you and Kandid8. If you have any questions
              relating to the Terms of Service please contact us at
              <a href="#"> suppoort@kandid8.com</a> .
            </li>

            <li>
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING THE
              SERVICE. BY ACCESSING, BROWSING OR OTHERWISE USING THE SITE OR THE
              SERVICE: (A) YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND
              AGREE TO BE BOUND BY THESE TERMS OF SERVICE whether on your own
              behalf (where you are acting as an individual) or on behalf of the
              company, business or ORGANIZATION you represent; AND (b) YOU
              ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND
              BY THIS AGREEMENT. IF YOU DO NOT AGREE TO FOLLOW AND BE BOUND BY
              THESE TERMS OF SERVICE, YOU MAY NOT ACCESS, BROWSE OR OTHERWISE
              USE THE SITE OR THE SERVICE.
            </li>
          </ol>
        </p>

        <h4>2. Definitions</h4>
        <p>
          <ol>
            <li>
              kandid8.com (“Site”) is a website operated by TechBeaver
              (“TechBeaver”, “we”, “our” or “us”), a company registered in
              Palmgroove,Nigeria and under company number 2867508 whose
              registered address is at 15, Ibiyemi Street, Plamgroove, Shomolu,
              Lagos, Nigeria.
            </li>

            <li>
              “Account”​ means an account with us for use of our Service that is
              specific to you and is protected by a password.
            </li>

            <li>
              “Account Holder”​ means the organization or individual(s) named as
              the Examiner during sign up on our Site.
            </li>

            <li>
              “Candidate”​ means a person who accesses the Service to take an
              Assessment Session.
            </li>

            <li>
              “Challenge”​ means an Assessment that enables Candidates to get
              ranked for employment.
            </li>

            <li>
              “Content”​ means all text, information, material, data, software,
              applications, database content, executable codes, images, audio or
              video material, including the metadata relating to any such
              content, in whatever medium or form provided by us or by you in
              relation to the Site.
            </li>
            <li>
              “Fees”​ means the amount payable by you to us in consideration for
              our Service, as stated during signup on our Site.
            </li>
            <li>
              “Initial Term”​ means the initial term of the Agreement set out
              during signup on our Site.
            </li>
            <li>
              “Order Form”​ means a binding sales order form executed by you and
              Kandid8 and incorporating this Agreement. In the event of any new
              or different terms in the Order Form, such new or different terms
              shall supersede any inconsistent terms in this Agreement, but only
              to the extent of the inconsistency.
            </li>
            <li>“Paid Plan”​ means the paid Service you subscribe for.</li>
            <li>
              “Past Challenge”​ means a Challenge that ended and its Task has
              been included into Public Tasks.
            </li>
            <li>
              “Plan”​ means a Paid Plan subject to these Terms of Service.
            </li>
            <li>
              “Policies”​ means the following documents displayed on the Site:
              Privacy Policy (“Privacy Policy”), Cookie Policy (“Cookie
              Policy”), Data Processing Agreement (“Data Processing Agreement”)
              and Programmer Terms of Service (“Programmer Terms of Service”).
            </li>
            <li>
              “Candidate”​ any person who takes or is expected to take a Test
              owned by an Account Holder and/or any other programming tests
              offered by Kandid8.
            </li>
            <li>
              “Candidates Terms of Service”​ means the terms of service found on
              the Site, which Candidates will be required to accept before using
              our Service.
            </li>
            <li>
              “Public Task”​ means a Task that is or previously has been
              published on the Programmer’s Home area of the Service.
            </li>
            <li>
              “Self-Service Paid Plan” means the paid Service you subscribe for
              by submitting your details and making payment via the Site.
            </li>
            <li>
              “Service”​ means the computer programming testing service made
              available by accessing the Site.
            </li>
            <li>
              “Task(s)”​ means a description of a programming problem created by
              Kandid8.
            </li>
            <li>
              “Term”​ means the period of time made up of the Initial Term and
              any Renewal Period referred to in Section 5.1. “Assessment”​ means
              a set of up to 6 Tasks selected by the Examiner to assess its
              Candidates.
            </li>
            <li>
              “Assessment Session”​ means an attempt made by a Candidate to
              solve an Assessment.
            </li>
            <li>
              “You”​ means an Account Holder, their employees, agents or anyone
              authorized by us to use the Service.
            </li>
          </ol>
        </p>

        <h4>3. Modifications; Other Agreements; Grant of License</h4>
        <p>
          <ol>
            <li>
              We may make modifications, deletions and/or additions to these
              Terms of Service (“Changes”) at any time. Changes will be
              effective: (i) thirty (30) days after we provide notice of the
              Changes, whether such notice is provided through the Service user
              interface, is sent to the e-mail address associated with your
              account or otherwise; or (ii) when you opt-in or otherwise
              expressly agree to the Changes or a version of this Agreement
              incorporating the Changes, whichever comes first.
            </li>
            <li>
              Our Privacy Policy, found at https://kandid8.com/privacy-policy/,
              is hereby incorporated into these Terms of Service. Please read
              this notice carefully for details relating to the collection, use,
              and disclosure of your personal information.
            </li>
            <li>
              Our Cookie Policy, found at https://kandid8.com/cookie-policy/, is
              hereby incorporated into these Terms of Service. Please read this
              notice carefully for details relating to the use of cookies on the
              Site.
            </li>
            <li>
              Our Data Processing Agreement, found at
              https://kandid8.com/data-processing-agreement/, is hereby
              incorporated into these Terms of Service. The Data Processing
              Agreement sets forth the rights and obligations of the parties
              with respect to the processing of personal data within Nigeria and
              Africa. To the extent that Kandid8 processes any personal
              information that is subject to the General Data Protection
              Regulation on your behalf in the provision of the Service
              hereunder, the terms of the Data Processing Agreement, which are
              hereby incorporated by reference, shall apply.
            </li>
          </ol>
        </p>

        <h4>4. The Service</h4>
        <p>
          <ol>
            <li>
              The Examiner is responsible for making all arrangements necessary
              in order for it, its employees, agents or anyone else authorized
              by us, including its Candidates, to have access to the Service and
              for procuring and maintaining all necessary equipment and network
              connections in order to use the Service. Examiners are responsible
              for ensuring that their Candidates have the details they need to
              use the Service. Examiners shall ensure that their Candidates
              agree to the Policies before they take an Assessment Session.
            </li>
            <li>
              We represent and warrant that we shall use commercially reasonable
              efforts to provide the Service in accordance with its
              documentation and in accordance with industry standards.
            </li>
            <li>
              We will make commercially reasonable efforts to notify you of any
              planned maintenance that might impact the availability of the
              Service and we will inform Examiners of their Candidates’
              Assessment Sessions which have been affected by an unexpected
              system downtime.
            </li>
            <li>
              If we discover a problem in one of our Assessments that may have
              affected the result of an Assessment Session, as our sole
              obligation in relation thereto, we will inform Examiners whose
              Candidates took the affected Assessment Session(s) up to 1 month
              prior to the discovery of the problem.
            </li>
            <li>
              We shall make available appropriate technical support (during our
              regular business hours) to all Examiners and Candidates of the
              Service.
            </li>
            <li>
              The number of Assessment Sessions available to you depends on the
              Plan you choose and the number of Assessment Sessions you
              subscribe for on the Order Form or during signup on our Site
              (“Assessment Session Allowance”). Once your Assessment Session
              Allowance has been used your right to grant Candidates access to
              the Service will be automatically suspended until the following
              subscription period. We will use commercially reasonable efforts
              to notify you when you have reached your Test Session Allowance
              and you will have the option to purchase additional usage rights
              or upgrade your Plan.
            </li>
            <li>
              An Assessment Session may only be used to assess one Candidate.
            </li>
            <li>
              In connection with the Services, we may provide access to
              functionality which allows for real-time audio and/or video
              communication sessions (“Live Sessions”) with Candidates. Live
              Sessions will only be enabled to the extent both participants
              expressly agree to participate in such Live Session. Provided that
              you grant such access, you hereby agree to use Live Session
              functionality in compliance with all applicable laws, and that the
              Live Session functionality is subject in all ways to the terms and
              conditions governing the Service in these Terms of Service,
              including, without limitation, the restrictions in Section 9,
              which shall apply to all audio and/or video content transmitted
              through the Service using Live Session functionality. You agree
              not to use any third-party functionality, devices, software or
              other techniques to record, transcribe or otherwise create a
              record of Live Sessions (including, without limitation, images
              captures), other than routine notes taken using pen/pencil and
              paper or standard word processing software. Notwithstanding the
              foregoing, to the extent another user records, transcribe or
              otherwise captures a record a Live Session in violation of the
              following sense, you hereby agree that we have no liability in
              respect thereto, and your sole recourse is against the other user.
              You hereby consent to the capture of your likeness, surroundings,
              and audio originating from your audio/video capture devices to the
              extent necessary for us and our third-party partners to provide
              the Live Session functionality to you.
            </li>
          </ol>
        </p>
        <h4>5. Duration</h4>
        <p>
          <ol>
            <li>
              If you subscribe for a Paid Plan, the Agreement lasts for the
              Initial Term unless it is otherwise terminated in accordance with
              these Terms of Service (including, without limitation, Section 7).
            </li>
          </ol>
        </p>

        <h4>6. Price and Payment</h4>
        <p>
          <ol>
            <li>
              The Examiner is responsible for paying all relevant Fees. Fees are
              due and payable as of the date you sign up to the Service (unless
              otherwise specified in the Order Form).
            </li>
            <li>
              All Fees are exclusive of applicable sales tax, VAT and any other
              applicable taxes, unless otherwise stated. You may be charged, and
              are solely responsible for, all applicable taxes.
            </li>
            <li>
              Kandid8 accepts online payment for any Paid Plan: If you chose to
              pay us online (via credit card or other payment accepted by
              Kandid8), payment processing for the Service is provided by such
              third party payment processor as we may utilize from time to time
              (“Payment Processor”). Kandid8 does not collect or store your
              credit card information. You can find out more about both our
              privacy practices in our Privacy Policy and the links therein. By
              providing a credit card or other payment method accepted by
              Kandid8, you represent and warrant that you are authorized to use
              the designated payment method and that you authorize our Payment
              Processor to charge your payment method for the total amount of
              your transaction and any applicable fees (including any applicable
              taxes and other charges). If the payment method you provide cannot
              be verified, is invalid or is otherwise not acceptable, your
              account may be suspended or cancelled. You must resolve any
              problem we or our Payment Processor encounters in order to proceed
              with your use of the Service.
            </li>
            <article>
              <p>
                In addition, the Paid Plan processed via Order Form may be paid
                by the following payment methods:
              </p>
              <ol>
                <li>Check</li>
                <li>Bank Transfer</li>
              </ol>
            </article>
          </ol>
        </p>

        <h4>7. Price and Payment</h4>
        <p>
          <ol>
            <li>
              Termination of this Agreement (however caused) shall be without
              prejudice to any rights or liabilities accrued up through the date
              of termination and shall not affect the continuation after
              termination of any provision expressly stated or implicitly
              surviving termination.
            </li>
            <li>
              In our sole discretion, we may: terminate this Agreement;
              temporarily or permanently withdraw any rights you have to use the
              Service; issue a notice; and/or take legal action at our sole
              discretion, or if you:
              <li>
                fail to pay any amount owed to us within 10 days of the due
                date;
              </li>
              <li>
                are in material or persistent breach of this Agreement and (if
                the breach is capable of remedy) fail to remedy such breach
                within 30 days of a notice requiring you to remedy that breach;
              </li>
              <li>
                are insolvent, bankrupt, unable to pay your debts, enter into
                any agreement with your creditors, or enter into administration;
                or
              </li>
              <li>are in breach of any applicable law, rule or regulation.</li>
            </li>
          </ol>
        </p>
        <h4>8. Account Security</h4>
        <p>
          <ol>
            <li>
              You are responsible for safeguarding any password given to you to
              access the Service and you must prevent any unauthorized use of
              these details. You agree not to disclose your password to any
              other person. If you believe an unauthorized person has access to
              the Service, you must notify us as soon as possible by e-mail to
              <a href="#"> suppoort@kandid8.com</a> . You are responsible for
              all activities occurring under your Account.
            </li>
          </ol>
        </p>

        <h4>9. Restrictions</h4>
        <h5>
          You must not sell, transfer or sub-license your access to the Service.
          You will not, and will not allow anyone else to:
        </h5>
        <p className="inner-list">
          <ol>
            <li>
              use the Service for any purpose that is unlawful or prohibited by
              these Terms of Service;
            </li>
            <li>
              attempt to copy, modify, duplicate, create derivative works from,
              frame, mirror, republish, download, display, transmit or
              distribute all or any portion of the Service, as applicable, in
              any form of media or by any means;
            </li>
            <li>
              use any robot, spider, site search or retrieval application, or
              any other device to copy, retrieve, archive or index any portion
              of the Site that requires authentication or is disabled by
              specification given in https://kandid8.com/robots.txt;
            </li>
            <li>
              attempt to reverse compile, disassemble, reverse engineer or
              otherwise reduce to human-perceivable form all or any part of the
              Service;
            </li>
            <li>
              access all or any part of the Service in order to build a product
              or service which competes with our Service or use or attempt to
              use the Service to directly compete with us (including by
              reselling access to the Service);
            </li>
            <li>
              use the Service in any manner that could interfere with any other
              party’s use of the Service;
            </li>
            <li>
              intentionally interfere with or damage the operation of the
              Service or any other user’s enjoyment of the Service, by any
              means, including uploading or otherwise disseminating viruses,
              worms, or other malicious code;
            </li>
            <li>
              remove, circumvent, disable, damage or otherwise interfere with
              any security-related features of the Service, features that
              prevent or restrict the use or copying of any content accessible
              through the Service, or features that enforce limitations on the
              use of the Service;
            </li>
            <li>
              attempt to gain unauthorized access to the Service, or any part of
              the Service, other accounts, computer systems or networks
              connected to the Service, or any part thereof, through hacking,
              password mining or any other means or interfere or attempt to
              interfere with the proper working of the Service or any activities
              conducted on the Service;
            </li>
            <li>
              obtain or attempt to obtain any materials or information through
              any means not intentionally made available through the Service;
            </li>
            <li>
              either modify the Service in any manner or form, or use modified
              versions of the Service, including (without limitation) for the
              purpose of obtaining unauthorized access to the Service; or
            </li>
            <li>
              deep-link to the Service, and: (a) you will promptly remove any
              links that we find objectionable in our sole discretion;and (b)
              you may not use any Kandid8 logos, graphics, or trademarks as part
              of the link without our express written consent.
            </li>
            <li>
              share user accounts among multiple employees or agents. Should you
              need accommodation for more Users than the current limit per your
              Agreement, please contact suppoort@kandid8.com .
            </li>
          </ol>
        </p>

        <h4>10. Your Responsibilities</h4>

        <h5>At all times while using the Service, you:</h5>
        <p className="inner-list">
          <ol>
            <li>
              must comply with all applicable laws, rules and regulations in
              connection with your use of the Service and this Agreement,
              including without limitation all employment laws;
            </li>
            <li>
              are responsible for any actions and omissions relating to the use
              of the Service by you and your Candidates;
            </li>
            <li>
              must obtain and maintain all necessary licenses, consents and
              permissions necessary for us to perform our obligations under the
              Agreement (for example software licenses for integrated tools,
              data protection permissions);
            </li>
            <li>
              are responsible for procuring and maintaining your network
              connections in order to access the Service and ensuring that your
              network and systems* comply with the relevant specifications
              provided by us from time to time.
            </li>
          </ol>
        </p>

        <h4>11. Acceptable Use</h4>
        <h5>
          You must ensure that the Service is not used to distribute in any way
          (including by your use, creation, provision or posting such Content on
          the Site and/or the Service) Content that may:
        </h5>
        <p className="inner-list">
          <ol>
            <li>
              contain any material which is false, unlawful, defamatory,
              libelous, threatening, pornographic, harassing, hateful, racially
              or ethnically offensive or encourages conduct that would be
              considered a criminal offense, violate any law or is otherwise
              inappropriate;
            </li>
            <li>
              be subject to third-party intellectual property or proprietary
              rights, including privacy and publicity rights, unless you are the
              owner of such rights or have permission from their rightful owner
              to post the material and to grant Kandid8 all of the license
              rights granted herein;
            </li>
            <li>
              infringe any copyright, database right, trademark or other third
              party right of any kind, deceive or harass any person, or promote
              any illegal activity, or permit any third party to do so;
            </li>
            <li>
              be an advertisement or marketing content or solicitations of
              business, or any content of a commercial nature; or
            </li>
            <li>
              be used to impersonate any person, misrepresent identity, or give
              the impression that they emanate from Kandid8, if this is not the
              case.
            </li>
          </ol>
        </p>

        <h5>
          We shall have the right to immediately remove any content described in
          Section 11.1.1 through and including Section 11.1.3 from the Site
          without notice to you. We may investigate an allegation that any
          Content does not conform to these Terms of Service and may determine
          in good faith and in our sole discretion whether to remove such
          Content, which we reserve the right to do at any time.
        </h5>

        <h4>12. Limitation of Liability</h4>
        <p className="reduced-text">
          <ol>
            <li>
              EXCEPT AS OTHERWISE SPECIFIED HEREIN, THE SERVICE AND ANY
              THIRD-PARTY, MEDIA, SOFTWARE, SERVICES, OR APPLICATIONS MADE
              AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SERVICE ARE PROVIDED
              “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
              IMPLIED. EXCEPT AS OTHERWISE SPECIFIED HEREIN, TO THE FULLEST
              EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, KANDID8, AND ITS
              SUPPLIERS AND PARTNERS, DISCLAIM ALL WARRANTIES, EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. KANDID8, AND
              ITS SUPPLIERS AND PARTNERS, DO NOT WARRANT THAT THE FEATURES
              CONTAINED IN THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
              KANDID8, AND ITS SUPPLIERS AND PARTNERS, DO NOT WARRANT OR MAKE
              ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF
              THE SERVICE IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY, OR
              OTHERWISE. EXCEPT AS OTHERWISE SPECIFIED HEREIN, YOU UNDERSTAND
              AND AGREE THAT YOU DOWNLOAD, OR OTHERWISE OBTAIN MEDIA, MATERIAL,
              OR OTHER DATA THROUGH THE USE OF THE SERVICE AT YOUR OWN
              DISCRETION AND RISK CERTAIN LAWS IN YOUR JURISDICTION MAY NOT
              ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
              LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
              ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
              APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
            </li>
            <li>
              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
              SHALL KANDID8 OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS,
              OR THIRD PARTY PARTNERS OR SUPPLIERS, BE LIABLE TO YOU FOR ANY
              SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
              THAT RESULT FROM YOUR USE OR THE INABILITY TO USE THE CONTENT,
              VISUAL INTERFACES, INFORMATION, GRAPHICS, DESIGN, COMPILATION,
              COMPUTER CODE, PRODUCTS, SOFTWARE, SERVICES, AND ALL OTHER
              ELEMENTS OF THE SERVICE THAT ARE PROVIDED BY KANDID8 ON THE
              SERVICE, THE SERVICE ITSELF, OR ANY OTHER INTERACTIONS WITH
              KANDID8, EVEN IF KANDID8 OR A KANDID8 AUTHORIZED REPRESENTATIVE
              HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE
              LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, KANDID8’S LIABILITY
              WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
            </li>
          </ol>
        </p>
        <h5>
          IN NO EVENT SHALL KANDID8’S OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES,
          AGENTS, OR THIRD PARTY PARTNERS OR SUPPLIERS’ TOTAL LIABILITY TO YOU
          FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR
          RELATING TO THESE TERMS OR YOUR USE OF THE SITE (WHETHER IN CONTRACT,
          TORT, WARRANTY, OR OTHERWISE) EXCEED THE FEES ACTUALLY PAID BY YOU TO
          KANDID8 IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING
          RISE TO THE APPLICABLE CLAIM.
          <br />
          <br />
          THESE LIMITATIONS SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY
          REASON OF ANY PRODUCTS OR SERVICES SOLD OR PROVIDED TO YOU BY THIRD
          PARTIES OTHER THAN KANDID8 AND RECEIVED BY YOU THROUGH OR ADVERTISED
          ON THE SERVICE OR RECEIVED BY YOU THROUGH ANY LINKS PROVIDED ON THE
          SERVICE.
        </h5>

        <h4>13. Indemnification</h4>
        <p>
          <ol>
            <li>
              The Examiner shall indemnify, defend and hold Kandid8 and its
              affiliated companies, suppliers, partners, agents and contractors
              harmless from and against any and all losses, damages, claims,
              liabilities or expenses (including reasonable lawyer’s fees and
              disbursements) arising out of a claim brought by a third party
              relating to: (i) Examiner’s use of the Service or their
              Candidates’ use of the Service (except to the extent caused by our
              gross negligence or willful misconduct) including, without
              limitation, any employment decisions, financial business, and
              commercial judgements, and acts or omissions made based on use of
              the Service; (ii) any breach of these Terms of Service or any
              representations, warranties or covenants herein; or (iii) any
              violation of any applicable law, rule or regulation by Examiner or
              its personnel in connection with the Service, including without
              limitation any violation of any employment laws.
            </li>
            <li>
              Kandid8 shall indemnify, defend (or settle), and hold harmless the
              Examiner from and against all claims, suits, liabilities losses,
              costs, damages, and expenses, including without limitation
              reasonable attorneys’ fees or expenses (“Losses”), arising out of
              or related to any third party claim that alleges that the Services
              infringe any copyright, trade secret, patent or trademark, or any
              other intellectual property right (“Intellectual Property”) of any
              third party brought against the Examiner. The Examiner shall
              provide Kandid8 with: (i) prompt written notice of such claim;
              (ii) control over the defense and settlement of such claim; and
              (iii) proper and full information and assistance to settle and/or
              defend any such claim. The foregoing provisions states the entire
              liability of Kandid8, and the sole remedy of the Examiner, with
              respect to any actual or alleged claim of infringement or
              misappropriation of the Intellectual Property of any third-party.
            </li>
          </ol>
        </p>

        <h4>14. Intellectual Property Rights</h4>
        <p>
          <ol>
            <li>
              We are the owner or licensee of all intellectual property rights
              (“IP Rights”) in the Service and the Site and any material written
              or published on it, including but not limited to, as between you
              and us, all Assessment results. All such rights are reserved, and
              no rights are granted or transferred hereunder except to the
              extent expressly licensed under these Terms of Service. As between
              the parties, each Candidate remains the owner of all his/her
              Candidate data
            </li>
            <li>
              You hereby grant Kandid8 a non-exclusive, perpetual, royalty-free,
              worldwide and irrevocable license to use any Content (including
              test cases and source code) provided by you through the Site in
              connection with providing the functionality of the Service
              (including through third party providers and contractors who help
              us provide the Service) and as provided under Section 14.1. You
              are responsible for ensuring that you have the rights or
              permission needed to comply with these Terms of Service.
            </li>
            <li>
              Without limiting the foregoing, any code entered into the Service
              by you may be stored and used by us and our third party providers
              and contractors who help us provide the Service in any way for the
              purpose of assessment, anti-cheating measures, demonstrating to
              third parties, and analysis.
            </li>
          </ol>
          <h4>15. Data Privacy</h4>
          <ol>
            <li>
              We will hold and process any data provided by you in accordance
              with our Privacy Policy and Data Processing Agreement.
            </li>
            <li>
              The Examiner is responsible at all times for ensuring the accuracy
              and legality of any data or Content in the Service relating to it
              or its users. You hereby represent and warrant that you have all
              necessary rights in your Content to upload such Content to the
              Service and for it to be used in all manners contemplated under
              the functionality of the Service.
            </li>
            <li>
              We will not be liable for the deletion, correction, alteration,
              destruction, damage, loss, disclosure or failure to store any of
              your Content or any other data using the Service.
            </li>
            <li>
              The Examiner must ensure that all relevant parties (including
              Candidates) have been informed of, and have consented to, any
              personal information being held and processed by us in accordance
              with our Privacy Policy and Data Processing Agreement on behalf of
              the Examiner. Examiner must notify us promptly if any information
              it has provided to us (including contact information) changes. We
              may process any personal information entered on the Service by you
              in accordance with our Privacy Policy and Data Processing
              Agreement.
            </li>
            <li>
              We will forward any data subject access and other requests to you.
            </li>
            <li>
              You may not use the Service for any illegal or unauthorized
              purpose. You are solely responsible for your conduct and any data,
              including but not limited to any Content, that you or your
              Candidates deposit on the Site. In particular, if you choose to
              use the Service to store Candidates’ personal information and if
              you or your Candidates are located in a jurisdiction that requires
              Candidates’ consent to store their personal information or has any
              other legal requirements before doing so, you shall obtain the
              Candidates’ consent and comply with all other legal requirements
              before the Candidate is sent an invitation to take an Assessment
              Session.
            </li>
            <li>
              We may disclose any data provided by you to law enforcement
              authorities as we reasonably feel is necessary.
            </li>
          </ol>
        </p>

        <h4>16. Feedback and Publicity</h4>
        <p>
          <ol>
            <li>
              We welcome comments, suggestions, ideas, enhancement requests,
              feedback, recommendations or other information provided by you or
              any other party relating to the Service (“Feedback”) from you. You
              agree to assign and hereby do assign to us all right, title and
              interest in and to such Feedback and agree that we may freely
              utilize such Feedback without compensation or attribution to you.
            </li>
            <li>
              You grant us the right to use your company name and logo as a
              reference for marketing or promotional purposes on our website and
              in other public or private communications with our existing or
              potential Examiners, subject to your standard trademark usage
              guidelines as provided to us from time to time. We do not want to
              list Examiners who do not want to be listed, so you may send us an
              email to suppoort@kandid8.com stating that your company does not
              wish to be used as a reference.
            </li>
          </ol>

          <h4>17. Confidentiality</h4>
          <ol>
            <li>
              Each Party agrees that all information that is disclosed, whether
              disclosed verbally, in writing, in electronic form or by any other
              means, by one party (“Discloser”) to the other Party (the
              “Recipient”), shall be considered confidential information of the
              Discloser (the “Information”). Codility’s Information shall
              include, without limitation, any Task, any Challenge, and
              statements and information on the Site (including Test Sessions
              and Test Session results). <br />
              <br />
              Notwithstanding the foregoing, the Information shall not include
              Public Tasks or Past Challenges. Recipient hereby agrees not to
              use the Information except for the purpose of performing its
              obligations and exercising its rights under these Terms of
              Service. Recipient agrees that it shall take all reasonable
              measures to protect the secrecy of and avoid disclosure and
              unauthorized use of the Information of the Discloser. Without
              limiting the foregoing, Recipient shall implement at least those
              protections for Information of the Discloser that Recipient takes
              to protect its own confidential information. <br />
              <br />
              Recipient agrees not to disclose any Information of Discloser to
              third parties or to employees of Recipient, except that Recipient
              may allow access to the Information of Discloser to those of its
              consultants, service providers, advisors, employees or other
              authorized representatives who are required to have the
              Information of Discloser to perform Recipient’s obligations under
              these Terms of Service, provided, however, that such consultants,
              service providers, advisors, employees or other authorized
              representatives have signed or are otherwise subject to an
              agreement imposing upon such person restrictions on use and
              disclosure of the Discloser’s Information that are at least as
              restrictive as those in these Terms of Service, prior to any
              disclosure of Discloser’s Information to such person. Recipient
              shall not reverse engineer, disassemble, decompile, or determine
              the composition of any prototypes, software or other tangible
              objects that embody any Information of Discloser and that are
              provided to Recipient hereunder. Recipient shall not make any
              copies of Information of Discloser unless the same are previously
              approved in writing by Discloser.
              <br />
              <br />
              The foregoing restrictions shall not apply to Information of
              Discloser that Recipient can establish: (i) was publicly known or
              made generally available in the public domain prior to the time of
              disclosure to Recipient by Discloser; (ii) becomes publicly known
              or made generally available after disclosure to Recipient by
              Discloser through no action or inaction of Recipient; (iii) is in
              the possession of Recipient, without confidentiality restrictions,
              at the time of disclosure by Discloser as shown by Recipient’s
              files and records immediately prior to the time of disclosure; or
              (iv) is obtained by the Recipient from a third party without a
              breach of any obligations of confidentiality and not under
              confidentiality obligations.
              <br />
              <br />
              Notwithstanding the foregoing, the Recipient may disclose the
              Information of the Discloser to the extent required by an
              applicable court order or by law; provided, however, that the
              Recipient that is so required to disclose the Information of the
              Discloser shall, when possible, give the Discloser reasonable
              advance notice of such disclosure and use reasonable efforts to
              secure confidential treatment of such Information (whether through
              protective order or otherwise).
            </li>
          </ol>
        </p>

        <h4>18. Miscellaneous</h4>
        <p>
          <ol>
            <li>
              You must give all notices under these Terms of Service to us at
              support@codility.com. We may give notice to you at an e-mail or
              postal address you provide to us, by posting a notice on the Site,
              or in any other way we deem appropriate. You agree that all
              agreements, notices, disclosures, and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing. Notice will be deemed served
              and received immediately (i) when posted on the Service; (ii) 24
              hours after an e-mail is sent; or (iii) three days after posting a
              letter.
            </li>
            <li>
              Neither party may assign its rights or delegate its duties under
              this Agreement, in whole or in part, by operation of law or
              otherwise, without the prior written consent of the other party,
              except that a party shall have the right to assign this Agreement
              in whole as part of a corporate reorganization, consolidation,
              merger, or sale of substantially all of its assets on notice to
              the other party, provided that such assignee is not a competitor
              of the non-assigning party and has the ability to assume the
              rights and obligations of the assigning party hereunder. Any
              attempted assignment or delegation in violation of this Section
              18.2 will be void. This Agreement shall be binding on, and shall
              indure to the benefit of, the parties and their respective
              successors and permitted assigns.
            </li>
            <li>
              The failure of us to enforce any provision of these Terms of
              Service shall not be deemed a waiver of such provision nor the
              right to enforce such provision. Furthermore, any waiver of any
              provision of these Terms of Service by any party will be effective
              only if in writing and signed by a party.
            </li>
            <li>
              These Terms of Service are governed by and construed in accordance
              with California law without giving effect to conflict of laws
              principles. Each party submits to the exclusive jurisdiction of
              the courts in San Mateo County.
            </li>
          </ol>
        </p>
      </main>

      <Footer />
    </div>
  );
}

export default TermsOfService;
